<style>
  .list-legislacao .v-list-item__title,
  .list-legislacao .v-icon {
    white-space: normal;
    line-height: 20px;
    color: #089BCE;
  }
</style>
<template>
  <div class="container--fluid fill-height">
    <v-container class="pa-10">
      <h2 class="text-h4 text-center font-weight-bold mb-10">
        {{ $t('legislacao.titulo') }}
      </h2>
      <v-card
        :elevation="6"
        rounded="xl"
        class="pa-10"
      >
        <v-card-text class="list-legislacao">
          <v-list-item-group
            v-for="documento in arquivos"
            :key="documento.id"
          >
            <v-list-item
              :href="documento.link_portal"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon
                  v-text="'mdi-download'"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ documento.text_pt }} <ChipDocumentoNovo :documento="documento" />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-pagination
            v-if="tamanho > 0"
            v-model="pagination.current"
            :length="pagination.total"
            @input="onPageChange"
          />
          <div
            v-else
            class="text-h4 text-center font-weight-bold"
          >
            {{ 'Carregando arquivos do servidor!' }}
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ChipDocumentoNovo from '../widget/ChipNovoDocumento.vue';

export default {
  name: 'Legislacao',
  components: {
    ChipDocumentoNovo,
  },
  data: () => ({
    item: '',
    arquivos: '',
    tamanho: '',
    rota: 'http://sysaga3-api.decea.local/portal/legislation/download/',
    pagination: {
      current: 1,
      total: 0,
    },
  }),
  mounted() {
    this.documentos();
  },
  methods: {
    async documentos() {
      const params = {
        status: 'Aprovado',
        tipo: 'Legislação',
      };
      try {
        const apiurl = `${process.env.VUE_APP_URL_API}/portal/legislation`;
        await this.$axios.get(`${apiurl}?page=${this.pagination.current}`, { params: JSON.stringify(params) })
          .then((response) => {
            this.arquivos = response.data.data;
            this.pagination.current = response.data.current_page;
            this.pagination.total = response.data.last_page;
            this.tamanho = response.data.data.length;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        console.warn('documentos', err);
      }
    },
    onPageChange() {
      this.documentos();
    },
  },
};
</script>
